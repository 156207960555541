import React from 'react';

const Tooltip = ({
  title,
  width = 'w-40',
  padding = 'p-2',
  color = 'ecox',
  rounded = 'rounded-md',
  text = 'text-sm text-white text-center font-normal',
  children,
}) => {
  const styles = `${width} ${padding} ${rounded} ${text}`;

  return (
    <div className={`tooltip-${color} fill-current text-${color}`}>
      {children}
      <span className={styles}>{title}</span>
    </div>
  );
};

export default Tooltip;
