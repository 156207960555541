import React, { useState } from "react";

export default function Drag({ maxIndex, index, handlerIndex }) {
  const [isDragging, setIsDragging] = useState(false);
  const [startDrag, setStartDrag] = useState(0);
  const [endDrag, setEndDrag] = useState(0);

  function handlerMouseMove(e) {
    if (!isDragging) return;

    e.persist();

    setEndDrag(e.clientX)
  }

  function handlerMouseDown(e) {
    setIsDragging(true);
    setStartDrag(e.clientX);
  }

  function handlerMouseUp(e) {
    const resultDrag = endDrag - startDrag;

    if (isDragging && resultDrag > 0){
      handlerIndex(!index ? maxIndex : index - 1);
    } else {
      handlerIndex(index >= maxIndex ? 0 : index + 1);
    }

    setIsDragging(false);
    setStartDrag(0);
  }

  return (
    /* eslint-disable-next-line */
    <div
      className="absolute top-0 left-0 w-full h-full lg:hidden z-20"
      onMouseDown={handlerMouseDown}
      onMouseUp={handlerMouseUp}
      onMouseOut={handlerMouseUp}
      onBlur={handlerMouseUp}
      onMouseMove={handlerMouseMove}

      onPointerDown={handlerMouseDown}
      onPointerOut={handlerMouseUp}
      onPointerMove={handlerMouseMove}
    ></div>
  );
}
