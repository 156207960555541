import React from "react";

import Slider from "../Slider/Index";

export default function Testimonials() {
  const items = [
    {
      text: "Excelente atendimento, rapidez, resultados de exames com muita qualidade e equipamentos modernos. Eu indico e recomendo.",
      textLink: "Carolina Palma. Gurupi-TO.",
    },
    {
      text: "Senti muita confiança no atendimento de todos na clínica, aí eu fiquei bem tranquila. Todos são muito simpáticos",
      textLink: "Ivanilde Maria da Silva. Figueirópolis-TO.",
    },
    {
      text: "Meus exames de rotina faço sempre na ECO-X há anos, pois confio e gosto muito do atendimento.",
      textLink: "Ana Paula Dantas Silva. Gurupi-TO.",
    },
    {
      text: "Pra mim foi nota 10 o atendimento. Foi a primeira vez que fiz exame na clínica e voltaria a qualquer hora que for preciso.",
      textLink: "Orlando Pedro dos Santos. Formoso do Araguaia-TO.",
    },
    {
      text: "Acessibilidade e atendimento personalizado para pacientes especiais. Adorei a equipe e o modo com que tratam as pessoas.",
      textLink: "Renato Borges. Gurupi-TO.",
    }
  ];

  return (
    <div className="w-full lg:h-80 bg-white pb-12 relative">
      <div className="flex flex-col items-center max-w-full mx-auto xl:max-w-screen-xl">
        <div className="flex flex-col items-center mt-12">
            <h2 className="text-lg font-semibold text-black-222">Depoimentos</h2>
            <div className="w-18 border border-ecox"></div>
        </div>

        <Slider items={items} typeItem="testimonials" textColor="text-gray-500" />
      </div>

      <div id="exames-e-servicos" className="lg:absolute lg:top-1/2 lg:left-0"></div>
    </div>
  );
}
