import React from 'react';
import { useTransition } from 'react-spring';

import Toast from './Toast';

const ToastContainer = ({ messages, direction, removeToast }) => {
  const messagesWithTransations = useTransition(
    messages,
    (message) => message.id,
    {
      from: { transform: 'translate3d(0,-300%,0)' },
      enter: { transform: 'translate3d(0,0px,0)' },
      leave: { transform: 'translate3d(0,-300%,0)' },
    },
  );

  const style = `flex flex-col ${direction} w-full max-w-screen-xxl fixed top-0 mt-24 lg:mt-30 p-8 overflow-hidden z-50`;

  return (
    <div className={`${messages.length ? style : ''}`}>
      {messagesWithTransations.map(({ item, key, props }) => (
        <Toast key={key} style={props} message={item} removeToast={removeToast} />
      ))}
    </div>
  );
};

export default ToastContainer;
