import React, { useEffect, useCallback, useState } from 'react';
import axios from 'axios'

export const Instagram = () => {
  const [posts, setPosts] = useState([]);

  const getPostsInstagram = useCallback(async () => {
    try {
      const urlInstragram = `https://www.instagram.com/graphql/query/?query_hash=44efc15d3c13342d02df0b5a9fa3d33f&variables=%7B%22id%22%3A%227459942250%22,%22first%22%3A4%7D`;

      const response = await axios.get(urlInstragram);

      const edges = response.data.data.user.edge_owner_to_timeline_media.edges;

      const postsInstagram = edges.map(edge => {
        return {
          id: edge.node.id,
          url: `https://www.instagram.com/p/${edge.node.shortcode}`,
          thumbnail_src: edge.node.thumbnail_src,
          caption: edge.node.edge_media_to_caption.edges[0].node.text
        }
      })
      setPosts(postsInstagram);
    } catch(error) {
      console.error('Loading Instagram error: ', error);
    }
  }, []);

  useEffect(() => {
    getPostsInstagram();
  }, [getPostsInstagram]);

  return (
    <>
      {posts && posts.length > 0 ? (
        <>
          {posts.map(post => (
            <div
              key={post.id}
              className="w-75 h-75 bg-white shadow-lg p-1 mt-8 rounded-lg"
            >
              <a
                href={post.url}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={post.thumbnail_src}
                  alt={post.caption} title={post.caption}
                  className="w-full h-full rounded-lg"
                />
              </a>
            </div>
          ))}
        </>
      ) : (<div>Carregando posts...</div>)}
    </>
  );
};
