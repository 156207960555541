import React, { useCallback, useEffect, useState } from "react";
import { useStaticQuery, graphql } from "gatsby";

import Slider from "../Slider/Index";

export default function Sliders() {
  const data = useStaticQuery(graphql `
    query {
      slider01: file(relativePath: {eq: "slider/01.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      slider02: file(relativePath: {eq: "slider/02.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      slider03: file(relativePath: {eq: "slider/03.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      slider04: file(relativePath: {eq: "slider/04.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const items = [
    {
      src: data.slider01.childImageSharp.fluid,
      title: "Ultrassonografia",
      // subtitle: "",
      text: "Método indolor e sem uso de radiação onde o equipamento digital de ultima geração permite maior segurança e qualidade na imagem com a ajuda de ondas sonoras. Por ser inócuo e operado por médicos, pode ser usado também para guiar biópsias/punções e conta com recursos extras como Doppler Colorido e US 3D/4D",
      // textLink: "Agende seu exame",
      // link: "https://agenda.ecoxdiagnosticos.com.br",
      textColor: "text-white"
    },
    {
      src: data.slider02.childImageSharp.fluid,
      title: "Raio-X Digital",
      // subtitle: "Mais praticidade",
      text: "O exame pode ser tanto impresso em filmes como visualizado em computadores, possibilita maior rapidez e segurança ao paciente, utilizando menores doses de radiação e oferecendo a melhor imagem. Sempre acompanhado por médicos radiologista na ECO-X",
      // textLink: "Veja seus exames",
      // link: "https://resultado.ecoxdiagnosticos.com.br",
      textColor: "text-white"
    },
    {
      src: data.slider03.childImageSharp.fluid,
      title: "Densitometria Óssea",
      // subtitle: "Mais praticidade",
      text: "Exame rápido e indolor, seguro por ter baixíssima dose de radiação, onde é possível, com a ajuda de um equipamento de ótima qualidade medir não só a densidade dos ossos mas também a taxa de gordura corporal. Oferece rapidez e conforto ao paciente.",
      // textLink: "Veja seus exames",
      // link: "https://resultado.ecoxdiagnosticos.com.br",
      textColor: "text-white"
    },
    {
      src: data.slider04.childImageSharp.fluid,
      title: "Ressonância Magnética",
      // subtitle: "Pré-agendamento",
      text: "Exame de alta tecnologia sem uso de radiação, em equipamento aberto, oferecendo maior conforto e tranquilidade ao paciente. Aliado a qualidade técnica e experiência profissional dos médicos radiologistas da clínica, que sempre acompanham presencialmente os exames, resulta num diagnóstico preciso e rápido.",
      // textLink: "Agende seu exame",
      // link: "https://agenda.ecoxdiagnosticos.com.br",
      textColor: "text-white"
    },
  ];

  const [urlSendWhatsapp, setUrlSendWhatsapp] = useState('');
  const fillWhatsappUrl = useCallback(() => {
    const urlApiWhatsapp = 'https://wa.me/?text=';
    const textSend = `Olá, gostaria de agendar um exame!`;
    setUrlSendWhatsapp(`${urlApiWhatsapp}${textSend.replace(' ', '%20')}`);
  }, []);

  useEffect(() => {
    if (urlSendWhatsapp === '') fillWhatsappUrl();
  }, [urlSendWhatsapp, fillWhatsappUrl]);


  return (
    <>
      <Slider items={items} />

      <div className="w-full lg:h-100 bg-ecox-social-bar relative text-white">
        <div className="max-w-full h-full mx-auto lg:flex lg:justify-between lg:max-w-screen-lg xl:max-w-screen-xl text-white">
          <div className="flex flex-col justify-center lg:max-w-xl flex-1 px-4 py-10 lg:p-0 lg:py-0 lg:pr-5">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 63 70"
              className="w-16 h-auto fill-current"
            >
              <path d="M56 7H41.37c-1.47-4.06-5.32-7-9.87-7-4.55 0-8.4 2.94-9.87 7H7c-3.85 0-7 3.15-7 7v49c0 3.85 3.15 7 7 7h49c3.85 0 7-3.15 7-7V14c0-3.85-3.15-7-7-7zM31.5 7a3.51 3.51 0 0 1 3.5 3.5 3.51 3.51 0 0 1-3.5 3.5 3.51 3.51 0 0 1-3.5-3.5A3.51 3.51 0 0 1 31.5 7zm7 49H14v-7h24.5v7zM49 42H14v-7h35v7zm0-14H14v-7h35v7z" />
            </svg>

            <h2 className="text-xl font-semibold mt-8">Exames online</h2>

            <p className="text-justify mt-2 text-sm leading-loose">Para que você tenha acesso ao resultado do seu exame, não é necessário ir até a clínica, agora você tem acesso online.</p>

            <button type="button" disabled className="px-4 py-2 border rounded-lg w-55 text-center mt-12 cursor-not-allowed" title="Em breve">Veja seus exames</button>
          </div>

          <div className="flex flex-col justify-center lg:max-w-xl flex-1 bg-ecox-400 px-4 py-10 lg:p-0 lg:py-0 lg:px-5">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 63 70"
              className="w-16 h-auto fill-current"
            >
              <path d="M21 31.5h-7v7h7v-7zm14 0h-7v7h7v-7zm14 0h-7v7h7v-7zM56 7h-3.5V0h-7v7h-28V0h-7v7H7C3.115 7 .035 10.15.035 14L0 63c0 3.85 3.115 7 7 7h49c3.85 0 7-3.15 7-7V14c0-3.85-3.15-7-7-7zm0 56H7V24.5h49V63z"/>
            </svg>

            <h2 className="text-xl font-semibold mt-8">Agende seu exame ou sua consulta</h2>

            <p className="text-justify mt-2 text-sm leading-loose">Agora ficou mais prático na hora de agendar seu exame ou consulta, basta escolher uma data e hora na nossa agenda online.</p>

            <a
              href={urlSendWhatsapp}
              target="_blank"
              rel="noopener noreferrer"
              className="px-4 py-2 border rounded-lg w-48 text-center mt-12"
            >
              Agende agora!
            </a>
          </div>

          <div className="flex flex-col justify-center lg:max-w-xl flex-1 px-4 py-10 lg:p-0 lg:py-0 lg:pl-5">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 45 70"
              className="h-18 w-12 fill-current"
            >
              <path d="M38.182.032L6.364 0C2.864 0 0 2.864 0 6.364v57.272C0 67.136 2.864 70 6.364 70h31.818c3.5 0 6.363-2.864 6.363-6.364V6.364c0-3.5-2.863-6.332-6.363-6.332zm0 57.24H6.364V12.728h31.818v44.546z"/>
            </svg>

            <h2 className="text-xl font-semibold mt-8">Aplicativo</h2>

            <p className="text-justify mt-2 text-sm leading-loose">Tenha acesso aos seus exames, agende seus exames ou consulta através de nosso aplicativo para Android ou iOS.</p>

            <button type="button" disabled className="px-4 py-2 border rounded-lg w-48 text-center mt-12 cursor-not-allowed" title="Em breve">Instale agora!</button>

          </div>
        </div>

        <div id="a-clinica" className="lg:absolute lg:top-1/2 lg:left-0"></div>
      </div>
    </>
  );
}
