const getValidationErrors = (err) => {
  const validationErrors = {};

  err.inner.forEach((error) => {
    validationErrors[error.path] = error.message;
  });

  return validationErrors;
};

const getValidationErrorsArray = (err) => {
  const validationErrors = [];

  err.inner.forEach((error) => {
    validationErrors.push({ id: error.path, message: error.message });
  });

  return validationErrors;
};

export { getValidationErrors, getValidationErrorsArray };
