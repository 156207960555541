import React, { useEffect } from 'react';
import {
  FiAlertCircle,
  FiCheckCircle,
  FiInfo,
  FiXCircle,
} from 'react-icons/fi';
import { animated } from 'react-spring';

const sizeIcon = 24;
const icons = {
  info: <FiInfo size={sizeIcon} />,
  success: <FiCheckCircle size={sizeIcon} />,
  error: <FiAlertCircle size={sizeIcon} />,
};

const Toast = ({ message, style, removeToast }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      removeToast(message.id);
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, [removeToast, message.id]);

  return (
    <animated.div className={`toast-${message.type || 'info'}`} style={style}>
      {icons[message.type || 'info']}

      <div
        className={`${message.description === '' ? 'flex items-center' : ''}`}
      >
        <strong>{message.title}</strong>

        {message.description && <p>{message.description}</p>}
      </div>

      <button onClick={() => removeToast(message.id)} type="button" className="focus:outline-none">
        <FiXCircle size={18} />
      </button>
    </animated.div>
  );
};

export default Toast;
