import React, { useCallback, useState, useRef, useEffect } from 'react';

import { useField } from '@unform/core';

import { FiAlertCircle } from 'react-icons/fi';

import Tooltip from '../Tooltip';

const Input = ({
  name,
  textColor = 'text-black-222',
  className = "",
  border = 'border-2 border-ecox-e6',
  roundend = 'rounded-md',
  background = 'bg-ecox-f9',
  classLabel = '',
  label = '',
  classLabelSpan = 'text-sm font-semibold text-black-222 uppercase mb-3',
  multiline = false,
  width = 'w-full',
  height = "h-32 lg:h-55",
  iconLeft: IconL,
  ...rest
}) => {
  const ref = useRef(null);

  const { fieldName, error, registerField } = useField(name);

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);

  const isErrored =
    error && !isFocused ? 'border-ecox-error text-ecox-error' : '';

  const styleFocused = isFocused
    ? 'border-2 border-ecox'
    : textColor;

  const styleText = isFilled && !isFocused ? 'text-ecox' : styleFocused;

  const newTextColor = isFocused ? 'text-ecox' : textColor;

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);

    setIsFilled(!!ref.current?.value);
  }, []);

  useEffect(() => {
    if (ref.current){
      registerField({
        name: fieldName,
        ref: ref.current,
        path: 'value',
      });
    }
  }, [fieldName, registerField]);

  const props = {
    ...rest,
    ref,
    id: fieldName,
    name: fieldName,
  };

  return (
    <div className={classLabel}>
      {label !== '' && <label className={classLabelSpan}>{label}</label>}

      <div
        className={`
          flex
          items-center
          justify-between
          ${!isFocused ? border : ''}
          ${roundend}
          ${background}
          px-3 py-2
          fill-current
          ${className}
          ${isErrored}
          ${styleText}
        `}
      >
        {multiline ? (
          <textarea
            className={`
              ${width}
              ${height}
              border-none
              pl-2 pr-3 py-1
              flex-1
              bg-transparent
              placeholder-black-999 text-base leading-6
              ${newTextColor}
              focus:outline-none
            `}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            {...props}
          />
        ) : (
          <input
            className={`border-none focus:outline-none pl-2 pr-3 py-1 flex-1 bg-transparent placeholder-black-999 text-base ${newTextColor}`}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            {...props}
          />
        )}

        {error && (
          <Tooltip title={error} color="ecox-error">
            <FiAlertCircle size={20} className="cursor-pointer" />
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default Input;
