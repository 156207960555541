import React from 'react';

import { Instagram } from '../Instagram';

const SocialNetwork = () => {
  return (
    <div className="w-full lg:h-140 bg-ecox-social-bar pb-20">
      <div className="flex flex-col items-center max-w-full mx-auto xl:max-w-screen-xl text-white">
        <div className="flex flex-col items-center mt-12">
            <h2 className="text-lg font-semibold text-white">Mídia Social</h2>
            <div className="w-20 border border-white"></div>
        </div>

        <div className="w-full flex flex-row flex-wrap justify-around lg:justify-between mt-10">
          <Instagram />
        </div>
      </div>

      <div id="contato"></div>
    </div>
  );
};

export default SocialNetwork;
