import React, { useState } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';

import menu from '../../config/menu';

import Logo from './Logos';
import { useCallback } from 'react';
import { useEffect } from 'react';

export default function Header({ className }) {
  const [leftDrawer, setLeftDrawer] = useState(false);

  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          email
          emailIcon
          phone
          phoneIcon
          whatsapp
          whatsappIcon
          social {
            name
            url
            icon
          }
        }
      }
    }
  `);

  const [urlSendWhatsapp, setUrlSendWhatsapp] = useState('');
  const fillWhatsappUrl = useCallback(() => {
    const urlApiWhatsapp = 'https://wa.me/?text=';
    const textSend = `Olá, gostaria de agendar um exame!`;
    setUrlSendWhatsapp(`${urlApiWhatsapp}${textSend.replace(' ', '%20')}`);
  }, []);

  useEffect(() => {
    if (urlSendWhatsapp === '') fillWhatsappUrl();
  }, [urlSendWhatsapp, fillWhatsappUrl]);

  return (
    <header className="fixed w-full bg-white shadow-md z-50">
      <div className="bg-ecox-social-bar h-8 hidden sm:block">
        <div className="flex justify-between h-8 mx-auto px-4 text-sm lg:px-0 lg:max-w-screen-lg xl:max-w-screen-xl">
          <div className="flex items-center">
            {data.site.siteMetadata.social.map(sn => {
              return (
                <a
                  href={sn.url}
                  target="_blank"
                  key={`social-bar-icon-${sn.name}`}
                  rel="noopener noreferrer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    className="w-4 h-4 fill-current text-white hover:text-gray-200 cursor-pointer mr-4"
                  >
                    <path d={sn.icon} />
                  </svg>
                </a>
              )
            })}
          </div>

          <div className="flex items-center">
            <div className="flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4 fill-current text-white mr-2"
                viewBox="0 0 512 512"
              >
                <path d={data.site.siteMetadata.emailIcon} />
              </svg>
              <span className="text-white">
                {data.site.siteMetadata.email}
              </span>
            </div>

            <a
              href={urlSendWhatsapp}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center mx-5"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4 fill-current text-white mr-2"
                viewBox="0 0 20 20"
              >
                <path d={data.site.siteMetadata.whatsappIcon} />
              </svg>
              <span className="text-white">
                {data.site.siteMetadata.whatsapp}
              </span>
            </a>

            <div className="flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4 fill-current text-white mr-2"
                viewBox="0 0 512 512"
              >
                <path d={data.site.siteMetadata.phoneIcon} />
              </svg>
              <span className="text-white">
                {data.site.siteMetadata.phone}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="lg:flex lg:items-center lg:justify-between lg:mx-auto lg:max-w-screen-lg xl:max-w-screen-xl ">
        <div className="flex justify-between items-center px-4 py-3 lg:px-0 lg:py-5">
          <div>
            <Logo className="h-12 sm:h-16"/>
          </div>

          <div className="flex lg:hidden">
            <button
              className="px-2 text-gray-800 hover:text-gray-600 focus:outline-none focus:text-gray-600"
              type="button"
              onClick={() => setLeftDrawer(!leftDrawer)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                className="h-6 w-6 fill-current"
              >
                <path
                  d={
                    leftDrawer ?
                      "M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" :
                      "M2 15.5v2h20v-2H2zm0-5v2h20v-2H2zm0-5v2h20v-2H2z"
                  }
                />
              </svg>
            </button>
          </div>
        </div>

        <nav className={`${leftDrawer ? "block" : "hidden"} lg:block`}>
          <div className="lg:flex lg:items-center px-2 pt-1 pb-3 lg:pr-0">
            {menu.map(m => {
              return (
                <Link
                  key={m.url}
                  to={m.url}
                  className={`
                    block
                    font-medium
                    ${m.url !== "#inicial" ? "mt-2 lg:mt-0" : ""}
                    px-3 py-1 rounded
                    hover:bg-gray-200
                    lg:text-sm lg:px-2
                    ${m.url === "#contato" ? "lg:pr-0" : ""}`}
                >
                  {m.name}
                </Link>
              )
            })}
          </div>
        </nav>
      </div>
    </header>
  );
}
