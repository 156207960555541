import React, { useState, useCallback, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";

import Slider from '../Slider/Index';

export default function Services() {
  const data = useStaticQuery(graphql `
    query {
      raiox: file(relativePath: {eq: "raio-x.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      ressonancia: file(relativePath: {eq: "ressonancia-campo-aberto.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      ultrassonografia: file(relativePath: {eq: "ultrassonografia.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      ultrassom3d: file(relativePath: {eq: "ultrassom-3d.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      ultrassomDoppler: file(relativePath: {eq: "ultrassonografia-doppler.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      biopsiasGuiadas: file(relativePath: {eq: "biopsias-guiadas-por-ultrassom.png"}) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      densitometria: file(relativePath: {eq: "densitometria.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const [items] = useState([
    {
      src: data.raiox.childImageSharp.fluid,
      title: "Radiografia Digital (RX)",
      subtitle: "Radiografia Digital (RX)",
      collapse: true,
      subitems: [
        'RX de Tórax',
        'RX de Arcos Costais',
        'RX de Colunas Cervical, Torácica e Lombar',
        'RX de Articulações',
        'RX de Seios da Face',
        'RX de de Cavum',
        'RX de Crânio',
        'RX de Abdôme',
        'RX de Idade Óssea',
        'RX Escanometria de Membros Inferiores ',
      ]
    },
    {
      src: data.ressonancia.childImageSharp.fluid,
      title: "Ressonância Magnética (RM)",
      subtitle: "Ressonância Magnética (RM)",
      collapse: true,
      subitems: [
        'RM de Crânio',
        'RM de Ouvidos',
        'RM de Hipófise',
        'RM de Colunas Cervical, Torácica e Lombar',
        'RM de Articulações',
        'RM de Bacia',
        'Rm de Pelve - Abdôme inferior',
        'Angio RM de Crânio',
      ]
    },
    {
      src: data.ultrassonografia.childImageSharp.fluid,
      title: "Ultrassonografia em Geral (UG)",
      subtitle: "Ultrassonografia em Geral (UG)",
      collapse: true,
      subitems: [
        'UG de Abdôme Total',
        'UG de Abdôme Superior',
        'UG de Rins e Vias Urinárias',
        'UG Pélvico (ginecológico)',
        'UG Pélvico-Endovaginal (2 exames em 1)',
        'UG de Mamas',
        'UG de Órgãos e estruturas superficiais',
        'UG de Articulações',
        'UG de Próstata',
        'UG Obstétrico',
      ]
    },
    {
      src: data.ultrassom3d.childImageSharp.fluid,
      title: "Ultrassonografia 3D/4D",
      subtitle: "Ultrassonografia 3D/4D",
      collapse: true,
      subitems: [
        'Ultrassom Obstétrico com Imagem tridimensional'
      ]
    },
    {
      src: data.ultrassomDoppler.childImageSharp.fluid,
      title: "Ultrassonografia Doppler (Colorido)",
      subtitle: "Ultrassonografia Doppler (Colorido)",
      collapse: true,
      subitems: [
        'Doppler de Tireóide',
        'Doppler de Orgãos e estruturas superficiais',
        'Doppler de Abdome',
        'Doppler Endovaginal',
        'Doppler de Articulações',
        'Doppler Obstétrico',
      ]
    },
    {
      src: data.biopsiasGuiadas.childImageSharp.fluid,
      title: "Biópsias Guiadas por Ultrassom",
      subtitle: "Biópsias Guiadas por Ultrassom",
      collapse: true,
      subitems: [
        'Biópsia de Mamas',
        'Biópsia de Próstata',
        'Biópsia de Tireoide (PAAF)',
      ]
    },
    {
      src: data.densitometria.childImageSharp.fluid,
      title: "Densitometria Óssea",
      subtitle: "Densitometria Óssea",
      collapse: true,
      subitems: [
        'Densitometria de rotina - dois ou mais segmentos',
        'Densitometria de Corpo Inteiro - composição corporal',
      ]
    }
  ]);

  const [sizeShow, setSizeShow] = useState(1);

  const handleSizeShow = useCallback((size) => {
    setSizeShow(size);
  }, []);

  useEffect(()=> {
    if(window.matchMedia('screen and (min-width: 640px) and (max-width: 1024px)').matches)
      handleSizeShow(2)
    else if(window.matchMedia('screen and (min-width: 1024px)').matches)
      handleSizeShow(3)
  }, [handleSizeShow])

  return (
    <>
      <div className="w-full lg:h-150 bg-ecox-social-bar pb-20 relative">
        <div className="flex flex-col items-center max-w-full mx-auto xl:max-w-screen-xl text-white">
          <div className="flex flex-col items-center mt-12">
              <h2 className="text-lg font-semibold text-white">Exames e Serviços</h2>
              <div className="w-20 border border-white"></div>
          </div>

          <div className="w-full flex flex-row justify-between mt-10">
            <Slider items={items} typeItem="services" className="flex lg:justify-center w-full relative" sizeShow={sizeShow} />
          </div>
        </div>

        <div id="convenios" className="lg:absolute lg:top-1/2 lg:left-0"></div>
      </div>
    </>
  );
}
