import React from "react"

import Header from "./Header"
import Footer from "./Footer"

export default function Layout({ children }) {
  return (
    <>
      <div className="antialiased">
        <Header />

        <main>{children}</main>

        <Footer />
      </div>
      <div
        className="toast-info toast-success toast-error tooltip-ecox-error tooltip-ecox-warning tooltip-ecox-green absolute"
        style={{
          position: 'absolute',
          visibility: 'hidden',
          display: 'none'
        }}
      ></div>
    </>
  )
}
