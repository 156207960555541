import React from "react";

export default function Arrow({ textColor, type, maxIndex, index, handlerIndex }) {
  function handlerArrow() {
    if (type === "prev") {
      if (!index)
        handlerIndex(maxIndex);
      else
        handlerIndex(index-1);
    } else {
      if (index >= maxIndex)
        handlerIndex(0);
      else
        handlerIndex(index+1);
    }
  }

  return (
    <button
        className={`flex items-center justify-center absolute z-20 top-0 ${type === "prev" ? "left-0 ml-3 lg:ml-10" : "right-0 mr-1 lg:mr-10"} h-full w-8 hidden lg:block focus:outline-none`}
        onClick={handlerArrow}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className={`fill-current ${textColor} h-6 w-6`}
        >
          <path d={`
            ${type === "prev" ?
              "M20.642 20.63V3.37c0-1.039-1.012-1.698-1.845-1.2L4.04 11c-.721.432-.721 1.568 0 2l14.756 8.83c.833.498 1.845-.161 1.845-1.2z"
            :
              "M3.5 3.37v17.26c0 1.039 1.012 1.698 1.845 1.2L20.101 13c.721-.432.721-1.568 0-2L5.345 2.17C4.512 1.671 3.5 2.33 3.5 3.37z"
            }`}
          />
        </svg>
      </button>
  );
}
