import React from "react";
import Img from "gatsby-image";
import { useStaticQuery, graphql } from "gatsby";

export default function Insurance() {
  const data = useStaticQuery(graphql `
    query {
      unimed: file(relativePath: {eq: "unimed.png"}) {
        childImageSharp {
          fluid(maxWidth: 129) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      ipasgu: file(relativePath: {eq: "ipasgu.png"}) {
        childImageSharp {
          fluid(maxWidth: 124) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      plansaude: file(relativePath: {eq: "plansaude.png"}) {
        childImageSharp {
          fluid(maxWidth: 98) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      acig: file(relativePath: {eq: "acig.png"}) {
        childImageSharp {
          fluid(maxWidth: 134) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      geap: file(relativePath: {eq: "brand_geap_horizontal.png"}) {
        childImageSharp {
          fluid(maxWidth: 276) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <div className="w-full lg:h-80 bg-white pb-12 relative">
      <div className="flex flex-col items-center max-w-full mx-auto xl:max-w-screen-xl">
        <div className="flex flex-col items-center mt-12">
            <h2 className="text-lg font-semibold text-black-222">Convênios</h2>
            <div className="w-12 border border-ecox"></div>
        </div>

        <div className="w-full flex flex-col md:flex-row items-center justify-between mt-12 md:mt-10 lg:mt-16 md:px-8 lg:px-0 pb-4">
          <a
            href="https://www.unimed.coop.br/web/gurupi/home"
            target="_blank"
            rel="noreferrer"
          >
            <Img
              fluid={data.unimed.childImageSharp.fluid}
              className="w-32-25 h-15"
              alt="Unimed Gurupi"
            />
          </a>

          <a
            href="http://www.gurupi.to.gov.br/?page=ipasgu"
            target="_blank"
            rel="noreferrer"
            className="mt-12 md:mt-0"
          >
            <Img
              fluid={data.ipasgu.childImageSharp.fluid}
              className="w-31 h-15"
              alt="IPASGU"
            />
          </a>

          <a
            href="https://plansaude.infoway-br.com/"
            target="_blank"
            rel="noreferrer"
            className="mt-12 md:mt-0"
          >
            <Img
              fluid={data.plansaude.childImageSharp.fluid}
              className="w-24-5 h-15"
              alt="PlanSaúde"
            />
          </a>

          <a
            href="https://www.aciggurupi.com.br/"
            target="_blank"
            rel="noreferrer"
            className="mt-12 md:mt-0"
          >
            <Img
              fluid={data.acig.childImageSharp.fluid}
              className="w-33-5 h-15"
              alt="ACIG"
            />
          </a>

          <a
            href="https://www.geap.com.br/"
            target="_blank"
            rel="noreferrer"
            className="mt-12 md:mt-0"
          >
            <Img
              fluid={data.geap.childImageSharp.fluid}
              className="w-69 h-15"
              alt="GEAP Saúde"
            />
          </a>
        </div>
      </div>

      <div id="midia-social" className="lg:absolute lg:top-1/2 lg:left-0"></div>
    </div>
  );
}
