import React, { useState, useCallback } from "react";
import Img from "gatsby-image";

export default function Item({ items, index, type, sizeShow }) {
  const [newItems, setNewItems] = useState(items);

  const [idOpenCollapse, setIdOpenCollapse] = useState('none');
  const handleCollapse = useCallback((id) => {
    setIdOpenCollapse((idOpen) => {
      return idOpen === id ? 'none' : id;
    });
  }, []);

  const wichItems = {
    img: () => (
      <>
        <div className="w-full h-100 xl:h-176 absolute top-0 z-10" style={{background: "linear-gradient(180deg, rgba(68, 68, 68, 1) -21.06%, rgba(255, 255, 255, 0) 76.56%)"}} />
        <Img
          fluid={newItems[index].src}
          className="w-full object-cover h-100 xl:object-25-0 xl:h-176"
        />

        <div className="absolute inset-0 max-w-full mx-auto lg:max-w-screen-lg xl:max-w-screen-xl p-4 lg:p-0 lg:py-4 z-20">
          <h3 className={`${newItems[index].textColor} text-2xl lg:text-6xl font-bold tracking-widest lg:tracking-title`}>{newItems[index].title}</h3>

          {newItems[index].subtitle && (<h4 className={`${newItems[index].textColor} text-lg lg:text-5xl font-semibold tracking-widest lg:tracking-widest`}>{newItems[index].subtitle}</h4>)}

          <p className={`${newItems[index].textColor} text-lg font-light mt-4`}>
            {newItems[index].text}
          </p>

          {newItems[index].link &&
            <a
              className="absolute inset-y-auto mt-8 lg:mt-16 p-4 bg-ecox text-white text-lg font-light rounded-lg shadow-xl"
              href={newItems[index].link}
              target="_blank"
              rel="noopener noreferrer"
            >
              {newItems[index].textLink}
            </a>
          }
        </div>
      </>
    ),

    testimonials: () => (
      <div className="flex flex-col items-center mt-12 mx-auto xl:max-w-screen-lg px-4">
        <p className="text-black-444 text-base text-center italic font-light">{newItems[index].text}</p>

        <div className="h-px w-48 border border-black-999 mt-8"></div>

        <p className="text-black-444 text-base text-center font-medium mt-2">{newItems[index].textLink}</p>
      </div>
    ),

    services: () => {
      let runItems = newItems.filter((_, i) => i < sizeShow);

      if (index) {
        const newItemsAux = newItems;
        const lastItemAux = newItemsAux.pop();

        newItemsAux.unshift(lastItemAux);

        runItems = newItemsAux.filter((item, i) => i < sizeShow)

        setNewItems(newItemsAux);

      }

      return (
        <>
          {runItems && runItems.map((item, i )=> (
            <div
              key={`item-services-${item.title}-${i}`}
              className={`flex flex-col w-90 ${idOpenCollapse === item.title ? 'h-full' : 'h-62'} relative bg-white rounded-tl-md rounded-tr-md overflow-hidden pb-4 mx-auto lg:mx-2`}
              style={{ boxShadow: "0px 8px 8px rgba(0, 0, 0, 0.25)" }}
            >
              <Img
                fluid={item.src}
                className="lg:max-w-none w-90 h-full absolute top-0"
                alt={item.subtitle}
              />

              <div className="flex flex-col">
                <div className="flex items-center justify-between mt-2 px-2">
                  <h2 className="text-sm text-black-444 font-medium">{item.title}</h2>
                  <button
                    type="button"
                    onClick={() => handleCollapse(item.title)}
                    className={`${item.collapse ? '' : 'hidden'} text-xxs text-ecox font-medium`}
                  >
                    ver mais
                  </button>

                </div>

                {item.subitems && (
                  <ul className={`${idOpenCollapse === item.title && item.subitems.length > 0 ? '' : 'hidden'} text-black-444 text-sm px-2 mt-2 list-inside list-disc`}>
                    {item.subitems.map((sub, y) => (
                      <li key={`subitem-services-${item.title}-${y}`}>{sub}</li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          ))}
        </>
      )
    }
  };

  const ItemRender = wichItems[type];

  return <ItemRender />;
}
