import React, { useState, useCallback, useEffect } from 'react';
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

export default function About() {
  const data = useStaticQuery(graphql `
    query {
      ecoxBuilding: file(relativePath: {eq: "ecox-building.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      ecoxInside: file(relativePath: {eq: "ecox-inside.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const [urlSendWhatsapp, setUrlSendWhatsapp] = useState('');
  const fillWhatsappUrl = useCallback(() => {
    const urlApiWhatsapp = 'https://wa.me/?text=';
    const textSend = `Olá, gostaria de agendar um exame!`;
    setUrlSendWhatsapp(`${urlApiWhatsapp}${textSend.replace(' ', '%20')}`);
  }, []);

  useEffect(() => {
    if (urlSendWhatsapp === '') fillWhatsappUrl();
  }, [urlSendWhatsapp, fillWhatsappUrl]);

  return (
    <div className="w-full py-10 bg-white relative">
      <div className="max-w-full h-full mx-auto lg:flex lg:justify-between lg:max-w-screen-lg xl:max-w-screen-xl text-white">
        <div className="flex flex-col w-full px-4 pb-4">
          <h2 className="text-lg font-semibold text-black-222">Bem-vindo a ECO-X</h2>
          <div className="w-20 border border-ecox"></div>

          <div className="flex flex-col lg:flex-row justify-between items-center w-full h-full mt-6">
            <div className="w-auto">
              <Img
                fluid={data.ecoxBuilding.childImageSharp.fluid}
                className="w-90 h-full md:w-176 lg:w-140 lg:h-120 rounded-md"
                style={{
                  boxShadow: "5px 4px 10px rgba(0, 0, 0, 0.25)"
                }}
              />
            </div>

            <div className="w-auto mt-6 lg:mt-0">
              <Img
                fluid={data.ecoxInside.childImageSharp.fluid}
                className="w-90 h-full md:w-176 lg:w-140 lg:h-120 rounded-md"
                style={{
                  boxShadow: "5px 4px 10px rgba(0, 0, 0, 0.25)"
                }}
              />
            </div>
          </div>

          <div className="flex flex-col w-full">
            <div className="flex flex-col">
              <h3 className="text-base text-black-444 font-semibold mt-4">O melhor da tecnologia  para você!</h3>

              <p className="mt-6 text-sm text-black-555 text-justify font-normal">São mais de 10 anos de atendimento humanizado e estrutura qualificada, com aparelhos digitais de última geração aliados a médicos com títulos de especialistas e a maior infra-estrutura da cidade de Gurupi.</p>

              <p className="mt-6 text-sm text-black-555 font-normal text-justify">Neste tempo conquistamos experiência e confiança. E sempre planejamos crescer com solidez e responsabilidade porque você é o mais importante para nós.</p>

              <p className="mt-6 text-sm text-black-555 font-normal">Entre em contato:</p>

              <div className="flex flex-col">
                <div className="flex items-center mt-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16 16"
                    className="h-4 fill-current text-ecox mr-4"
                  >
                    <path d="M3.218 6.924a13.465 13.465 0 0 0 5.858 5.858l1.955-1.955c.24-.24.596-.32.907-.214.995.33 2.07.507 3.173.507.489 0 .889.4.889.889v3.102c0 .489-.4.889-.889.889C6.764 16 0 9.236 0 .889 0 .4.4 0 .889 0H4c.489 0 .889.4.889.889 0 1.111.178 2.178.507 3.173a.892.892 0 0 1-.223.907L3.218 6.924z" />
                  </svg>

                  <span className="text-sm text-black-555">(63) 3312 - 3030 / 3312-4875</span>
                </div>

                <a
                  href={urlSendWhatsapp}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center mt-4"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    className="h-4 fill-current text-ecox mr-4"
                  >
                    <path d="M10 0a9.994 9.994 0 0 0-8.1 15.859L.656 19.574l3.844-1.23A9.9 9.9 0 0 0 10 20a10 10 0 0 0 0-20zm5.82 14.121a2.826 2.826 0 0 1-1.961 1.41c-.523.109-1.2.2-3.5-.754a12.552 12.552 0 0 1-4.98-4.4 5.726 5.726 0 0 1-1.192-3.014A3.2 3.2 0 0 1 5.211 4.93a1.457 1.457 0 0 1 1.023-.36c.125 0 .234.008.336.012.293.012.441.031.637.492.242.582.828 2.016.9 2.164a.609.609 0 0 1 .043.543 1.681 1.681 0 0 1-.324.457c-.148.168-.289.3-.434.484-.137.16-.289.328-.117.621a8.919 8.919 0 0 0 1.621 2.016 7.334 7.334 0 0 0 2.342 1.441.626.626 0 0 0 .7-.109 12.363 12.363 0 0 0 .781-1.035.564.564 0 0 1 .719-.219c.27.094 1.7.8 1.992.945s.488.219.559.34a2.5 2.5 0 0 1-.169 1.399z" />
                  </svg>

                  <span className="text-sm text-black-555">+55 63 99211 6875</span>
                </a>

                <div className="flex items-center mt-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 16"
                    className="w-5 h-4 fill-current text-ecox mr-4"
                  >
                    <path d="M18 0H2C.9 0 .01.9.01 2L0 14c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V2c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V2l8 5 8-5v2z"/>
                  </svg>

                  <span className="text-sm text-black-555"><a href="mailto:contato@ecoxdiagnosticos.com.br" target="_blank" rel="noopener noreferrer">contato@ecoxdiagnosticos.com.br</a></span>
                </div>
              </div>

              <div className="mt-8">
                <a href="/agendar" className="px-4 py-2 bg-ecox rounded-lg w-48 text-center">Agende seu exame</a>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div id="corpo-clinico" className="lg:absolute lg:top-1/2 lg:left-0"></div>
    </div>
  );
}
