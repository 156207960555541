module.exports = [
  {
    name: "Inicial",
    url: "",
  },
  {
    name: "A Clínica",
    url: "#a-clinica",
  },
  {
    name: "Corpo Clínico",
    url: "#corpo-clinico",
  },
  {
    name: "Depoimentos",
    url: "#depoimentos",
  },
  {
    name: "Exames e Serviços",
    url: "#exames-e-servicos",
  },
  {
    name: "Convênios",
    url: "#convenios",
  },
  {
    name: "Mídia Social",
    url: "#midia-social",
  },
  {
    name: "Contato",
    url: "#contato",
  },
]
