import React from "react";
import Img from "gatsby-image";
import { useStaticQuery, graphql } from "gatsby";

export default function Team() {
  const data = useStaticQuery(graphql `
    query {
      william: file(relativePath: {eq: "william.png"}) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      alessandra: file(relativePath: {eq: "alessandra.png"}) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      team: file(relativePath: {eq: "team.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <>
      <div className="w-full h-full bg-ecox-social-bar pb-20 relative">
        <div className="flex flex-col items-center max-w-full mx-auto xl:max-w-screen-xl text-white">
          <div className="flex flex-col items-center mt-12">
              <h2 className="text-lg font-semibold text-white">Corpo Clínico</h2>
              <div className="w-18 border border-white"></div>
          </div>

          <div className="flex flex-col items-center w-full lg:flex-row lg:items-start lg:justify-between mt-24">
            <div className="max-w-xs md:max-w-none md:w-145 h-full lg:h-122 flex flex-col pb-4 relative bg-white rounded-tl-md rounded-tr-md md:mr-6 lg:mr-0" style={{
              boxShadow: "0px 8px 8px rgba(0, 0, 0, 0.25)"
            }}>
              <div className="w-30 h-30 bg-black-666 absolute top-0 left-0 rounded-full flex items-center justify-center -mt-12 md:-mt-14">
                <Img
                  fluid={data.william.childImageSharp.fluid}
                  className="h-29 w-29 rounded-full object-cover"
                  alt="Dr. William da Silva Neves "
                />
              </div>

              <p className="text-sm ml-8 pl-24 py-2 text-white font-medium bg-black-666">Dr. William da Silva Neves </p>
              <p className="text-sm pl-32 text-black-444 font-normal mt-2">CRM-TO 2099  |  RQE 945  |  Responsável Técnico</p>

              <p className="text-sm px-4 text-black-444 font-normal text-justify leading-relaxed tracking-wide mt-4">
                Graduado em Medicina no ano de 2001 pela Faculdade de Medicina de São José do Rio Preto-FAMERP - Autarquia Estadual-SP.
              </p>
              <p className="text-sm px-4 text-black-444 font-normal text-justify leading-relaxed tracking-wide mt-4">
                Concluído Residência Médica em 2003 pelo Hospital de Base/FAMERP com Certificado de Especialista reconhecido pelo MEC em Clínica Médica.
              </p>
              <p className="text-sm px-4 text-black-444 font-normal text-justify leading-relaxed tracking-wide mt-4">
                Em 2006 concluído Residência Médica <strong>RADIOLOGIA</strong> e <strong>DIAGNÓSTICO POR IMAGEM</strong> no Instituto de Radiodiagnóstico Rio Preto-LTDA (ULTRA-X).
              </p>
              <p className="text-sm px-4 text-black-444 font-normal text-justify leading-relaxed tracking-wide mt-4">
                Tornou-se em 2008 Membro Titular do Colégio Brasileiro de Radiologia emitido o Título de Especialista em Radiologia e Diagnósticos por Imagem pela AMB – Associação Médica Brasileira.
              </p>
              <p className="text-sm px-4 text-black-444 font-normal text-justify leading-relaxed tracking-wide mt-4">
                Atuou como médico radiologista no Hospital de Referência de Gurupi desde o ano de sua aprovação no concurso público Estadual em 2009.
              </p>
              <p className="text-sm px-4 text-black-444 font-normal text-justify leading-relaxed tracking-wide mt-4">
                É Professor Assistente da Faculdade de Medicina da UNIRG – Gurupi –TO, na Disciplina de Imagenologia, desde sua aprovação no concurso público municipal no ano de 2007.
              </p>
            </div>

            <div className="max-w-xs md:max-w-none md:w-145 h-full lg:h-122 flex flex-col pb-4 relative bg-white rounded-tl-md rounded-tr-md mt-24 lg:mt-0 lg:ml-0" style={{
              boxShadow: "0px 8px 8px rgba(0, 0, 0, 0.25)"
            }}>
              <div className="w-30 h-30 bg-black-666 absolute top-0 left-0 rounded-full flex items-center justify-center -mt-12 md:-mt-14">
                <Img
                  fluid={data.alessandra.childImageSharp.fluid}
                  className="h-29 w-29 rounded-full object-cover"
                  alt="Dra. Alessandra Remondi Introcaso Di Marco"
                />
              </div>

              <p className="text-sm ml-8 pl-24 py-2 text-white font-medium bg-black-666">Dra. Alessandra R. Introcaso Di Marco</p>
              <p className="text-sm pl-32 text-black-444 font-normal mt-2">CRM-TO 2314 RQE 949.</p>

              <p className="text-sm px-4 text-black-444 font-normal text-justify leading-relaxed tracking-wide mt-4">
                Graduada em Medicina no ano de 2004 pela Universidade José do Rosário Velano – UNIFENAS – Alfenas-MG
              </p>
              <p className="text-sm px-4 text-black-444 font-normal text-justify leading-relaxed tracking-wide mt-4">
                Realizou Residência Médica em <strong>RADIOLOGIA</strong> e <strong>DIAGNÓSTICO POR IMAGEM</strong> no Instituto de Radiodiagnóstico Rio Preto-LTDA (ULTRA-X), concluído em 2008.
              </p>
              <p className="text-sm px-4 text-black-444 font-normal text-justify leading-relaxed tracking-wide mt-4">
                Tornou-se em 2009 Membro Titular do Colégio Brasileiro de Radiologia emitido o Título de Especialista em Radiologia e Diagnósticos por Imagem pela AMB – Associação Médica Brasileira.
              </p>
              <p className="text-sm px-4 text-black-444 font-normal text-justify leading-relaxed tracking-wide mt-4">
                Atuou como médica radiologista no Hospital de Referência de Gurupi - TO, tendo sido aprovada no concurso público Estadual em 2009.
              </p>
            </div>
          </div>

          <div className="flex flex-col items-center w-full md:w-145 lg:w-full md:flex md:flex-row md:items-start md:flex-wrap md:justify-around lg:justify-between mt-16">
            <div className="w-full max-w-xs md:w-full md:max-w-screen-md lg:max-w-none flex flex-col lg:flex-row lg:justify-start pb-4 lg:pb-0 relative bg-white rounded-tl-md rounded-tr-md overflow-hidden" style={{
              boxShadow: "0px 8px 8px rgba(0, 0, 0, 0.25)"
            }}>
              <div className="w-145 h-full">
                <Img
                  fluid={data.team.childImageSharp.fluid}
                  className="w-full max-w-xs md:max-w-none md:w-145 lg:h-full absolute top-0 object-cover"
                  alt="Corpo clínico ECO X Centro Médico e Diagnóstico"
                />
              </div>

              <div className="flex flex-col lg:w-full px-2 lg:px-4">
                <p className="text-sm lg:text-base text-black-444 font-semibold py-2">Equipe ECO X Centro Médico e Diagnóstico</p>

                <p className="text-sm text-black-444 font-normal text-justify leading-loose tracking-wider mt-4">
                  Os colaboradores e técnicos em Radiologia que atuam na clínica ECO-X são capacitados para melhor atender nossos clientes, com qualidade e agilidade, trazendo humanização e profissionalismo nas diferentes etapas dos serviços prestados. É um prazer participar da vida e da saúde de cada um, que acabam tornando-se queridos e fiéis amigos de toda nossa equipe de trabalho!
                </p>
              </div>
            </div>
          </div>
        </div>
        <div id="depoimentos" className="lg:absolute lg:top-1/2 lg:left-0"></div>
      </div>
    </>
  );
}
