import React, { useRef, useCallback, useState, useEffect } from "react";
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { uuid } from 'uuidv4';

import { MdHome, MdPhone, MdEmail } from 'react-icons/md';

import { getValidationErrors } from '../../utils/getValidationErrors';

import ToastContainer from '../ToastContainer';
import Input from '../Input';

import goHash from '../../utils/goHash';

export default function Contact() {
  const formRef = useRef(null);

  const [messages, setMessages] = useState([]);
  const [from, setFrom] = useState('');

  const addToast = useCallback(
    ({
      type,
      title,
      description,
      direction = 'items-center',
    }) => {
      setFrom(direction);

      const id = uuid();

      const toast = {
        id,
        type,
        title,
        description,
      };

      setMessages((oldToast) => [...oldToast, toast]);
    },
    [],
  );
  const removeToast = useCallback((id) => {
    setMessages((toast) => toast.filter((message) => message.id !== id));
  }, []);

  const handleSubmit = useCallback(
    async (data, { reset }) => {
      try {
        formRef.current.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          email: Yup.string()
            .required('E-mail obrigatório')
            .email('Digite um email válido'),
          subject: Yup.string().required('Assunto obrigatório'),
          message: Yup.string().required('Assunto obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        // await axios.post(apiUrl, {
        //   Name: data.name,
        //   Email: data.email,
        //   Subject: data.subject,
        //   Message: data.message,
        // });

        addToast({
          title: 'Mensagem enviada!',
          description: 'Sua mensagem foi enviada com sucesso!',
          type: 'success',
        });

        reset();
        goHash('');
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);

          formRef.current.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro ao enviar mensagem!',
          description: 'Ocorreu um erro ao enviar mensagem. Tente novamente!',
        });
      }
    },
    [addToast],
  );

  const [urlSendWhatsapp, setUrlSendWhatsapp] = useState('');
  const fillWhatsappUrl = useCallback(() => {
    const urlApiWhatsapp = 'https://wa.me/?text=';
    const textSend = `Olá, gostaria de agendar um exame!`;
    setUrlSendWhatsapp(`${urlApiWhatsapp}${textSend.replace(' ', '%20')}`);
  }, []);
  useEffect(() => {
    if (urlSendWhatsapp === '') fillWhatsappUrl();
  }, [urlSendWhatsapp, fillWhatsappUrl]);

  return (
    <div className="w-full h-full bg-white pb-20">
      <div className="flex flex-col items-center max-w-full mx-auto xl:max-w-screen-xl">
        <div className="flex flex-col items-center mt-12">
            <h2 className="text-lg font-semibold text-black-222">Contato</h2>
            <div className="w-12 border border-ecox"></div>
        </div>

        <div className="w-full flex flex-col md:flex-row items-center justify-between mt-12 md:mt-10 lg:mt-16 border border-ecox-e6 shadow-md">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3906.3996525539374!2d-49.07252768466846!3d-11.736869991674222!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x933e9510bc582ab7%3A0x531e45e68a69bc2c!2sEco-X%20Diagn%C3%B3sticos%20M%C3%A9dicos!5e0!3m2!1spt-BR!2sbr!4v1591130243559!5m2!1spt-BR!2sbr"
            style={{ border: 0, }}
            title="Endereço da Clínica no Google Maps"
            className="w-full max-w-screen-xl h-60 lg:h-100"
          />
        </div>

        <div className="w-full flex flex-row justify-between mt-12 md:mt-10 lg:mt-16 px-4 md:px-8 lg:px-0 pb-4">
          <Form
            onSubmit={handleSubmit}
            ref={formRef}
            className="flex flex-col w-full max-w-4xl"
          >
            <div className="flex">
              <Input
                name="message"
                placeholder="Mensagem"
                label="Envie uma mensagem"
                classLabel="flex flex-col w-full"
                multiline
              />
            </div>

            <div className="flex flex-col lg:flex-row justify-between mt-8">
              <Input
                name="name"
                placeholder="Informe seu nome"
                label="Nome completo"
                classLabel="flex flex-col w-full lg:mr-4"
              />

              <Input
                name="email"
                type="email"
                placeholder="Informe seu email"
                label="E-mail"
                classLabel="flex flex-col w-full mt-8 lg:mt-0 lg:ml-4"
              />
            </div>

            <div className="flex mt-8">
              <Input
                name="subject"
                placeholder="Informe um assunto"
                label="Assunto"
                classLabel="flex flex-col w-full"
              />
            </div>

            <div className="flex flex-1 justify-end mt-8">
              <div className="w-full lg:w-1/2 lg:pl-4">
                <button
                  type="submit"
                  className="w-full text-base text-white font-bold rounded-md bg-ecox h-14 shadow-lg hover:bg-ecox-400 focus:outline-none"
                >
                  Enviar
                </button>
              </div>
            </div>
          </Form>

          <div className="hidden lg:flex lg:flex-col lg:justify-start lg:w-84 lg:h-64-5 lg:text-sm lg:text-black-444">
            <div className="flex flex-row items-start mt-8">
              <MdHome size={30} className="mr-4" />
              <span className="font-normal"><strong className="font-semibold">Av Ceará, N° 2559, esq. com Av. Beira Rio.</strong> Gurupi -TO</span>
            </div>

            <div className="flex flex-row items-start mt-6">
              <MdPhone size={24} className="mr-4" />
              <span className="font-semibold">(63) 3312 - 3030 / 3312-4875</span>
            </div>

            <a
              href={urlSendWhatsapp}
              target="_blank"
              rel="noopener noreferrer"
              className="flex flex-row items-start mt-6"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                className="h-5 fill-current mr-4"
              >
                <path d="M10 0a9.994 9.994 0 0 0-8.1 15.859L.656 19.574l3.844-1.23A9.9 9.9 0 0 0 10 20a10 10 0 0 0 0-20zm5.82 14.121a2.826 2.826 0 0 1-1.961 1.41c-.523.109-1.2.2-3.5-.754a12.552 12.552 0 0 1-4.98-4.4 5.726 5.726 0 0 1-1.192-3.014A3.2 3.2 0 0 1 5.211 4.93a1.457 1.457 0 0 1 1.023-.36c.125 0 .234.008.336.012.293.012.441.031.637.492.242.582.828 2.016.9 2.164a.609.609 0 0 1 .043.543 1.681 1.681 0 0 1-.324.457c-.148.168-.289.3-.434.484-.137.16-.289.328-.117.621a8.919 8.919 0 0 0 1.621 2.016 7.334 7.334 0 0 0 2.342 1.441.626.626 0 0 0 .7-.109 12.363 12.363 0 0 0 .781-1.035.564.564 0 0 1 .719-.219c.27.094 1.7.8 1.992.945s.488.219.559.34a2.5 2.5 0 0 1-.169 1.399z" />
              </svg>

              <span className="font-semibold">+55 63 99211 6875</span>
            </a>

            <div className="flex flex-row items-start mt-6">
              <MdEmail size={24} className="mr-4" />
              <span className="font-semibold">contato@ecoxdiagnosticos.com.br</span>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer messages={messages} direction={from} removeToast={removeToast} />
    </div>
  );
}
