import React from 'react'
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

import Layout from '../components/Layout';
import Sliders from '../components/pages/Sliders';
import About from '../components/pages/About';
import Team from '../components/pages/Team';
import Testimonials from '../components/pages/Testimonials';
import Services from '../components/pages/Services';
import Insurance from '../components/pages/Insurance';
import SocialNetwork from '../components/pages/SocialNetwork';
import Contact from '../components/pages/Contact';

export default function Index() {
  const data = useStaticQuery(graphql `
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <Layout>
      <Helmet>
        <title>{`${data.site.siteMetadata.title}`} </title>
      </Helmet>

      <div className="pt-18 lg:pt-34">
        <Sliders />

        <About />

        <Team />

        <Testimonials />

        <Services />

        <Insurance />

        <SocialNetwork />

        <Contact />
      </div>
    </Layout>
  );
}
