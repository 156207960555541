import React, { useState } from "react";

import Arrow from "./Arrow";
import Item from "./Item";
import Drag from "./Drag";

export default function Index({
  id="",
  textColor = "text-white",
  className = "relative w-full",
  items,
  typeItem = "img",
  sizeShow = 0
}) {
  const [activeIndex, setActiveIndex] = useState(0);
  const maxIndex = (items.length - 1);

  function handlerIndex(index) {
    setActiveIndex(index);
  }

  return (
    <div className={className} id={id}>
      <Arrow
        type="prev"
        maxIndex={maxIndex}
        index={activeIndex}
        handlerIndex={handlerIndex}
        textColor={textColor}
      />

      <Item items={items} index={activeIndex} type={typeItem} sizeShow={sizeShow} />

      <Arrow
        type="next"
        maxIndex={maxIndex}
        index={activeIndex}
        handlerIndex={handlerIndex}
        textColor={textColor}
      />

      <Drag
        maxIndex={maxIndex}
        index={activeIndex}
        handlerIndex={handlerIndex}
      />
    </div>
  )
}
