import React from 'react';

// import Logo from './Logos';

export default function Footer() {
  return (
    <footer className="flex flex-col justify-between bg-black-333 w-full text-white">
      {/* <div className="w-full">
        <div className="flex felx-row justify-between container mx-auto">
          <Logo className="h-12 sm:h-16" type="white"/>
        </div>
      </div> */}
      <div className="bg-black-222 w-full h-14 flex items-center">
        <div className="flex felx-row items-center justify-center container mx-auto h-8 text-gray-100 text-xxs sm:text-xs">
          Todos direitos reservados a ECO-X. {"\u{00A9}"}2020.
          By&nbsp;<a href="https://gomes.eti.br" target="_blank" rel="noopener noreferrer" className="text-ecox-social-bar">Gomes</a>.
        </div>
      </div>
    </footer>
  );
}
